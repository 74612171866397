import { useState } from "react";
import { fixOnboardingIssue } from "../services/issues";
import { useDispatch } from "react-redux";
import { setAlertModal } from "../redux/slices/modal";


const Support = () => {
  const dispatch = useDispatch()
    const [phoneNumber, setPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false)
  
    const resolveIssue = async (e) => {
      e.preventDefault();
      setLoading(true)
      const res = await fixOnboardingIssue({ phoneNumber });

      dispatch(
        setAlertModal({
          status: true,
          message: `${phoneNumber} - ${res?.data.message}`,
        })
      );
      setLoading(false)
      console.log("res", res);
    };
    return (
        <div className="container">
        <img
          className="logo"
          src="https://www.mtn.co.rw/wp-content/uploads/2022/09/mtn-logo-nav-new-2048x1062.webp"
          alt="Logo"
        />
        <div className="form-container">
          <form onSubmit={resolveIssue}>
            <h3>Macye Macye Self Support</h3>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="form-field"
              placeholder="Enter MSISDN"
              required
            />
            <br />
            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? "Resolving..." : "Resolve"}
            </button>
          </form>
        </div>
      </div>
    );
};

export default Support;