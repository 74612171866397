import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
    name: "modal",
    initialState: {
      alertModal: {
        status: false,
        type: "",
        message: "",
      },  
    },
  
    reducers: {
      setAlertModal: (state, { payload }) => {
        state.alertModal = {
          status: payload.status,
          type: payload.type,
          message: payload.message,
        };
      },

    },
  });
  
  export const { setAlertModal } = modalSlice.actions;
  export default modalSlice.reducer;