import { useDispatch, useSelector } from "react-redux";
import { FaCheck, FaInfo, FaInfoCircle, FaTimes } from "react-icons/fa";
import { setAlertModal } from "../../redux/slices/modal";
import "../../assets/css/modal.css";

const AlertModal = () => {
  const alert = useSelector((state) => state.modal?.alertModal);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(
      setAlertModal({
        status: false,
        message: "",
        type: "",
        heading: "",
      })
    );
  };

  return (
    alert?.status && (
      <div className="alert-modal alertPOP">
        <div className="alert-modal-overlay"></div>
        <div className="alert-modal-card vivify popInBottom">
          <div className="close-alert-button"></div>

          <div className="alert-modal-body">
            <br />

            <div
              style={{ padding: "10px", backgroundColor: "#ffcc00" }}
              className="alert-modal-icon"
            >
              <i className="fa fa-check">
                <FaInfoCircle />
              </i>
            </div>
       
            <p
              style={{ lineHeight: "30px", fontSize: 20 }}
            >
             {alert.message}
            </p>

            <div className="alert-modal-button  mt-3">
              <button
                onClick={() => closeModal()}
                className="modal-close-button py-3"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default AlertModal;
