import AlertModal from "./components/modals/AlertModal";
import Support from "./screens/Support";

const App = () => {
 

  return (
    <>
    <AlertModal/>
    <Support/>
    </>
  );
};

export default App;
