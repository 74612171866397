import { combineReducers } from 'redux';
import modalSlice from "./modal"


const combinedSlices = combineReducers({
    modal: modalSlice,

});

export default combinedSlices;
